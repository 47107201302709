import settingService from "../services/settingService";
import MyMenu from "./MyMenu";
import { TabView, TabPanel } from 'primereact/tabview';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Skeleton } from 'primereact/skeleton';
import { useEffect } from "react";
import React, { useState } from "react";
import { Button } from "primereact/button";
import { FloatLabel } from "primereact/floatlabel";
import { InputTextarea } from "primereact/inputtextarea";
import { Accordion, AccordionTab } from 'primereact/accordion';
import { InputText } from "primereact/inputtext";
import { Toast } from 'primereact/toast';
import { useRef } from 'react';
import { useNavigate } from "react-router-dom";
import AuthService from "../services/auth.service";

const EmailSetting = () => {

    const [depotName, setDepotName] = useState("");
    const [depotCode, setDepotCode] = useState("");
    const [unit, setUnit] = useState("");
    const [relatedPerson, setRelatedPerson] = useState("");
    const [email, setEmail] = useState("");

    const [settingList, setSettingList] = useState([]);


    const [alarmType, setAlarmType] = useState("temperature")
    const [isLoading, setIsLoading] = useState(true)
    const [activeIndex, setActiveIndex] = useState(0)


    useEffect(() => {
        setIsLoading(true)
        settingService.getAlarmEMailAddress(alarmType).then(res => {
            setSettingList(res)
            setIsLoading(false)
        })
    }, [activeIndex]);

    const getAlarmTypeIndex = (index) => {

        setActiveIndex(index);
        if (index == 0)
            setAlarmType("temperature")
        else if (index == 1)
            setAlarmType("other")
    };

    const updateButtonTemplate = (setting) => {

        return (
            <div className="flex justify-content-evenly gap-4">
                {<Button onClick={() => updateClickAction(setting.id, setting.relatedPerson, setting.email)} label="Güncelle" severity="warning" rounded raised text />}
            </div>
        )
    }
    const updateClickAction = (id, person, email) => {

        const response = settingService.updateAlarmEMailAddress(id, person, email);
    }



    const deleteButtonTemplate = (setting) => {

        return (
            <div className="flex justify-content-evenly gap-4">
                {<Button onClick={() => deleteClickAction(setting.id)} label="Sil" severity="warning" rounded raised text />}
            </div>
        )
    }
    const deleteClickAction = (id) => {

        settingService.deleteAlarmEMailAddress(id).then(res => {

            let _settingList = settingList.filter((val) => val.id !== id);

            setSettingList(_settingList);
            show("success", "Başarılı", "Silme işlemi başarılı")

        }).catch(err => {
            show("danger", "Hata", "Silme işlemi başarısız.")

        });
    }

    const toast = useRef(null);

    const show = (severity, summary, detail) => {
        toast.current.show({ severity: severity, summary: summary, detail: detail, life: 5000 });
    };

    const createClickAction = (depotcode, depotname, unit, relatedperson, email, type) => {
        settingService.createAlarmEMailAddress(depotcode, depotname,
            unit, relatedperson, email, type
        ).then(response => {

            if (response.success) {
                show("success", "Başarılı", "Mail Ekleme işlemi başarılı.")
            }

        }).catch(err => {
            show("error", "Hata", "Mail Ekleme işlemi başarısız.")

        })
    }



    const items = Array.from({ length: 20 }, (v, i) => i);
    return (
        <>
            <MyMenu></MyMenu>
            <Toast ref={toast} />
            <TabView activeIndex={activeIndex} onTabChange={(e) => getAlarmTypeIndex(e.index)}>
                <TabPanel header="Sıcaklık Alarmı">
                    <p className="m-0">
                        <div className="flex justify-content gap-5 mt-5">
                            <div >
                                {isLoading && <DataTable scrollable scrollHeight="50vh"
                                    showGridlines columnResizeMode="expand" value={items} tableStyle={{ minWidth: '100%', maxWidth: '100%' }}>
                                    <Column field="depotCode" header="Depo Kodu" body={<Skeleton></Skeleton>} />
                                    <Column field="depotName" header="Depo Adı" body={<Skeleton></Skeleton>} />
                                    <Column field="unit" header="Birim" body={<Skeleton></Skeleton>} />
                                    <Column field="relatedPerson" header="İlgili Kişi" body={<Skeleton></Skeleton>} />
                                    <Column field="email" header="E-Mail" body={<Skeleton></Skeleton>} />
                                    <Column field="Delete" header="Sil" body={<Skeleton></Skeleton>} />
                                </DataTable>}


                                {!isLoading && <>
                                    <DataTable loading={isLoading}
                                        showGridlines columnResizeMode="expand" value={settingList} tableStyle={{ minWidth: '90rem' }}>
                                        <Column field="depotCode" header="Depo Kodu" />
                                        <Column field="depotName" header="Depo Adı" />
                                        <Column field="unit" header="Birim" />
                                        <Column field="relatedPerson" header="İlgili Kişi" />
                                        <Column field="email" header="E-Mail" />
                                        <Column field="Delete" header="Sil" body={deleteButtonTemplate} />
                                    </DataTable>
                                </>}
                            </div>
                        </div>
                    </p>
                </TabPanel>
                <TabPanel header="Diğer Alarmlar">
                    <p className="m-0">
                        {isLoading && <DataTable scrollable scrollHeight="50vh"
                            showGridlines columnResizeMode="expand" value={items} tableStyle={{ minWidth: '100%', maxWidth: '100%' }}>
                            <Column field="depotCode" header="Depo Kodu" body={<Skeleton></Skeleton>} />
                            <Column field="depotName" header="Depo Adı" body={<Skeleton></Skeleton>} />
                            <Column field="unit" header="Birim" body={<Skeleton></Skeleton>} />
                            <Column field="relatedPerson" header="İlgili Kişi" body={<Skeleton></Skeleton>} />
                            <Column field="email" header="E-Mail" body={<Skeleton></Skeleton>} />
                            <Column field="Delete" header="Sil" body={<Skeleton></Skeleton>} />
                        </DataTable>}


                        {!isLoading && <>
                            <DataTable loading={isLoading}
                                showGridlines columnResizeMode="expand" value={settingList} tableStyle={{ minWidth: '90rem' }}>
                                <Column field="depotCode" header="Depo Kodu" />
                                <Column field="depotName" header="Depo Adı" />
                                <Column field="unit" header="Birim" />
                                <Column field="relatedPerson" header="İlgili Kişi" />
                                <Column field="email" header="E-Mail" />
                                <Column field="Delete" header="Sil" body={deleteButtonTemplate} />
                            </DataTable>
                        </>}
                    </p>
                </TabPanel>
                <TabPanel header="Alarm Mail Bilgisi Ekle">
                    <p className="m-0">
                        <Accordion activeIndex={0}>
                            <AccordionTab header="Sıcaklık">
                                <p className="m-0">
                                    <div className="p-inputgroup justify-content-center gap-5 mt-7">
                                        <div className="w-1">
                                            <FloatLabel>
                                                <InputText id="depot_code" onChange={(e) => setDepotCode(e.target.value)} />
                                                <label htmlFor="depot_code">Depo Kodu</label>
                                            </FloatLabel>
                                        </div>
                                        <div className="w-1">
                                            <FloatLabel >
                                                <InputText id="depot_name" onChange={(e) => setDepotName(e.target.value)} />
                                                <label htmlFor="depot_name">Depo Adı</label>
                                            </FloatLabel>
                                        </div>
                                        <div className="w-1">
                                            <FloatLabel>
                                                <InputText id="unit" onChange={(e) => setUnit(e.target.value)} />
                                                <label htmlFor="unit">Birim</label>
                                            </FloatLabel>
                                        </div>
                                        <div className="w-1">
                                            <FloatLabel>
                                                <InputText id="related_person" onChange={(e) => setRelatedPerson(e.target.value)} />
                                                <label htmlFor="related_person">İlgili Kişi</label>
                                            </FloatLabel>
                                        </div>
                                        <div className="w-1">
                                            <FloatLabel>
                                                <InputText id="e_mail" onChange={(e) => setEmail(e.target.value)} />
                                                <label htmlFor="e_mail">E-mail</label>
                                            </FloatLabel>
                                        </div>
                                        <div className="w-max">
                                            <Button label="Kaydet" onClick={() => createClickAction(depotCode, depotName, unit, relatedPerson, email, "temperature")} />
                                        </div>
                                    </div>
                                </p>
                            </AccordionTab>
                            <AccordionTab header="Diğer">
                                <p className="m-0">
                                    <div className="p-inputgroup justify-content-center gap-5 mt-7">
                                        <div className="w-1">
                                            <FloatLabel>
                                                <InputText id="depot_code" onChange={(e) => setDepotCode(e.target.value)} />
                                                <label htmlFor="depot_code">Depo Kodu</label>
                                            </FloatLabel>
                                        </div>
                                        <div className="w-1">
                                            <FloatLabel >
                                                <InputText id="depot_name" onChange={(e) => setDepotName(e.target.value)} />
                                                <label htmlFor="depot_name">Depo Adı</label>
                                            </FloatLabel>
                                        </div>
                                        <div className="w-1">
                                            <FloatLabel>
                                                <InputText id="unit" onChange={(e) => setUnit(e.target.value)} />
                                                <label htmlFor="unit">Birim</label>
                                            </FloatLabel>
                                        </div>
                                        <div className="w-1">
                                            <FloatLabel>
                                                <InputText id="related_person" onChange={(e) => setRelatedPerson(e.target.value)} />
                                                <label htmlFor="related_person">İlgili Kişi</label>
                                            </FloatLabel>
                                        </div>
                                        <div className="w-1">
                                            <FloatLabel>
                                                <InputText id="e_mail" onChange={(e) => setEmail(e.target.value)} />
                                                <label htmlFor="e_mail">E-mail</label>
                                            </FloatLabel>
                                        </div>
                                        <div className="w-max">
                                            <Button label="Kaydet" onClick={() => createClickAction(depotCode, depotName, unit, relatedPerson, email, "other")} />
                                        </div>
                                    </div>
                                </p>
                            </AccordionTab>
                        </Accordion>
                    </p>
                </TabPanel>
            </TabView>
        </>
    )
}


export default EmailSetting;