import { Menubar } from "primereact/menubar";
import { useNavigate } from "react-router-dom";
import AuthService from "../services/auth.service";
import { useState } from "react";

const MyMenu = () => {


    const [token, setToken] = useState(undefined);
    const tkn = localStorage.getItem("token");
    const role = JSON.parse(atob(tkn.split(".")[1])).role;

    const navigate = useNavigate();
    const logOut = () => {
        AuthService.logout();
        setToken(undefined);
        navigate("/")
    };

    const start = <img alt="logo" src="/image.png" height="50px"></img>


    const itemsAdmin = [
        {
            label: 'Realizasyon',
            icon: 'pi pi-table',
            command: () => {
                navigate("/realization")
            }
        },
        {
            label: 'Excel Aktarma',
            icon: 'pi pi-file-export',
            command: () => {
                navigate("/export")
            }
        },
        {
            label: 'Ayarlar',
            icon: 'pi pi-cog',
            command: () => {
                navigate("/settings")
            }
        },
        {
            label: 'Çıkış',
            icon: 'pi pi-sign-out',
            command: () => {
                logOut()
            }
        }

    ];

    const itemsUser = [
        {
            label: 'Realizasyon',
            icon: 'pi pi-table',
            command: () => {
                navigate("/realization")
            }

        },
        {
            label: 'Çıkış',
            icon: 'pi pi-sign-out',
            command: () => {
                logOut()
            }
        }

    ];


    return (
        <>
            <Menubar model={role == "ADMIN" ? itemsAdmin : itemsUser} start={start} className="bg-white" />
        </>
    )


}

export default MyMenu;