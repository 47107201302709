import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar } from "primereact/calendar";
import { FloatLabel } from "primereact/floatlabel";
import { InputNumber } from "primereact/inputnumber";
import { Paginator } from "primereact/paginator";
import { Skeleton } from 'primereact/skeleton';
import { useEffect } from "react";
import MyMenu from "./MyMenu";
import { Button } from "primereact/button";
import ReportService from "../services/ReportService";
import React, { useState } from "react";
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import { useRef } from 'react';

const Realization = () => {

    const [planId, setPlanId] = useState("");
    const [routePlan, setRoutePlan] = useState([]);
    const [isLoading, setIsLoading] = useState(true)
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(50);
    const [totalPageCount, setTotalPageCount] = useState(0)
    const [myCurrentPage, setMyCurrentPage] = useState(1)
    const toast = useRef(null);

    const show = (severity, summary, detail) => {
        toast.current.show({severity:severity, summary: summary, detail:detail, life: 5000});
    };

    const formatDate = (date) => {

        if (date != '-' && date != 'Bilgi yok' && date != '') {
            let myDate = new Date(date);
            let hour = myDate.getHours();
            myDate.setHours(hour + 3);

            return myDate.toLocaleString('tr-TR', { timeZone: 'Europe/Istanbul' });
        }

        return date;


    }

    const onPageChange = (e, index) => {
        setIsLoading(true)
        setMyCurrentPage(e.page + 1)
        setFirst(e.first);
        setRows(e.rows);
    };

    const resendClickAction = (id) => {

       ReportService.reSendAllStepOrtec(id)
        .then(response => {
            show("info", 'Başarılı' ,"Plana ait adımlar tekrar gönderildi: " + id)
        }).catch(err => {
            console.log(err);
            show("danger", 'Hata' ,"Plana ait adımlar gönderilemedi :  " + id)
        });
    }


    const resendStepClickAction = (id, address, type) => {
        
         ReportService.reSendStepOrtec(id, address, type).then(data => {
            console.log(data)
            show("info", 'Sonuç' ,data.data.message + " : " + id)
         }).catch(err => {
            show("danger", 'Hata' ,"Gönderim işlemi hatalı:  " + id)
         });
    }


    const rowClass = (data) => {
        return {
            'bg-blue-50': data.stepOrder == 1
        };
    };

    const planIdTemplate = (plan) => {

        const trioPlate = plan.trioPlate == 'Plaka Sistemde Kayıtlı Değil';

        return (
            <div >

                {plan.stepOrder == 1 &&

                    <div className='flex justify-content-evenly align-content-center gap-3'>
                        {trioPlate && <Button disabled rounded text raised> Tekrar Gönder </Button>}
                        {!trioPlate && <Button onClick={() => resendClickAction(plan.id)} rounded text raised> Tekrar Gönder </Button>}
                        <div className="mt-3" >{plan.planId + " - " + plan.trioPlate}</div >
                    </div>

                }
            </div>
        )
    }

    const dateFormatterBody = (plan) => {

        const formattedDate = formatDate(plan.date);
        return (
            <div>
                
                {plan.stepOrder == 1 && <div className="mt-2" >{formattedDate}</div >}
            </div>
        )
    }
    const arrivedAtFormatterBody1 = (plan) => {

        const formattedDate = formatDate(plan.arrivedAt);
        return (
            <div>

                {<div className="mt-2" >{formattedDate}</div >}
            </div>
        )
    }
    const departedAtFormatterBody2 = (plan) => {

        const formattedDate = formatDate(plan.departedAt);
        return (
            <div>

                {<div className="mt-2" >{formattedDate}</div >}
            </div>
        )
    }

    const planIdTemplate3 = (plan) => {
        return (
            <div>

                {plan.stepOrder == 1 && <div className="mt-2" >{plan.plate}</div >}
            </div>
        )
    }

    const planIdTemplate4 = (plan) => {

        const isDone = plan.isDone
        let element;
        if (isDone == 'Tamamlandı')
            element = <Tag value="Tamamlandı" severity="success"></Tag>
        else
            element = <Tag value="Tamamlanmadı" severity="danger"></Tag>


        return (
            <div>

                {plan.stepOrder == 1 && element}
            </div>
        )
    }

    const resendBodyTemplate = (plan) => {

        let disable = plan.stepOrder == 1;
        let disable2 = plan.stepOrder == plan.stepCount;
        const trioPlate = plan.trioPlate == 'Plaka Sistemde Kayıtlı Değil';

        if (trioPlate) {
            disable = true;
            disable2 = true;
        }

        return (
            <div className="flex justify-content-evenly gap-4">
                {disable && <Button label="Giriş" severity="success" disabled rounded raised text />}
                {!disable && <Button onClick={() => resendStepClickAction(plan.id, plan.address, 'A')} label="Giriş" severity="success" rounded raised text />}

                {disable2 && <Button label="Çıkış" severity="warning" disabled rounded raised text />}
                {!disable2 && <Button onClick={() => resendStepClickAction(plan.id, plan.address, 'D')} label="Çıkış" severity="warning" rounded raised text />}

            </div>
        )
    }


    let starDateHour = new Date();
    starDateHour.setHours(0)
    starDateHour.setMinutes(0)

    const [starDate, setStartDate] = useState(starDateHour);
    const [endDate, setEndDate] = useState(new Date());


    useEffect(() => {
        ReportService.getRoutePlan(starDateHour, new Date(), planId, myCurrentPage, 50).then(res => {
            console.log(res)
            setTotalPageCount(res.totalPage * 50)
            setRoutePlan(res.getRoutePlansList)
            setIsLoading(false)
        })
    }, [myCurrentPage]);


    const findPlans = () => {
        setIsLoading(true)
        ReportService.getRoutePlan(starDate, endDate, planId, myCurrentPage, 50).then(res => {
            console.log(res)
            
            setTotalPageCount(res.totalPage * 50)
            setRoutePlan(res.getRoutePlansList)
            setIsLoading(false)

        }).catch(err => {
            console.log("Realization errror , ")
            console.log(err)
        })
    }
    const items = Array.from({ length: 20 }, (v, i) => i);

    return (
        <div>
            <MyMenu></MyMenu>
            <Toast ref={toast} />
            <div className="flex justify-content gap-5 mt-5">
                <div >
                    <div className="p-inputgroup justify-content-center gap-5 mb-5">
                        <div className="w-3">
                            <FloatLabel >
                                <Calendar inputId="start_date" showIcon showTime hourFormat="24" dateFormat="dd.mm.yy" value={starDate} onChange={(e) => setStartDate(e.value)} showButtonBar />
                                <label htmlFor="start_date">Başlangıç Tarihi</label>
                            </FloatLabel>
                        </div>
                        <div className="w-3">
                            <FloatLabel>
                                <Calendar inputId="end_date" showIcon showTime hourFormat="24" dateFormat="dd.mm.yy" value={endDate} onChange={(e) => setEndDate(e.value)} showButtonBar />
                                <label htmlFor="end_date">Bitiş Tarihi</label>
                            </FloatLabel>
                        </div>
                        <div className="w-1">
                            <FloatLabel>
                                <InputNumber inputId="plan_id" value={planId} onValueChange={(e) => setPlanId(e.value)} useGrouping={false} style={{ with: '15rem' }} />
                                <label htmlFor="plan_id">Plan Id</label>
                            </FloatLabel>
                        </div>
                        <div className="w-max">
                            <Button label="Listele" onClick={() => findPlans()} />
                        </div>
                    </div>



                    {isLoading && <DataTable scrollable scrollHeight="50vh" rowClassName={rowClass}
                        showGridlines columnResizeMode="expand" value={items} tableStyle={{ minWidth: '100%', maxWidth: '100%' }}
                    >
                        <Column field="planId" header="Plan" body={<Skeleton></Skeleton>} />
                        <Column field="date" header="Tarih" body={<Skeleton></Skeleton>} />
                        <Column field="plate" header="Plaka" body={<Skeleton></Skeleton>} />
                        <Column field="isDone" header="Tamamlanma" body={<Skeleton></Skeleton>} />
                        <Column field="depot" header="Depo" body={<Skeleton></Skeleton>} />
                        <Column field="address" header="Durak" body={<Skeleton></Skeleton>} />
                        <Column field="isArrive" header="Varış" body={<Skeleton></Skeleton>} />
                        <Column field="arrivedAt" header="Varış Tarihi" body={<Skeleton></Skeleton>} />
                        <Column field="isDeparted" header="Çıkış" body={<Skeleton></Skeleton>} />
                        <Column field="departedAt" header="Çıkış Tarihi" body={<Skeleton></Skeleton>} />
                        <Column field="location" header="Lokasyon" body={<Skeleton></Skeleton>} />
                        <Column field="arriveRealizationResponse" header="Ortec varış" body={<Skeleton></Skeleton>} />
                        <Column field="departRealizationResponse" header="Ortec çıkış" body={<Skeleton></Skeleton>} />
                        <Column field="resend" header="Tekrar Gönder" body={<Skeleton></Skeleton>} />
                    </DataTable>}


                    {!isLoading && <>
                        <DataTable loading={isLoading} rowClassName={rowClass}
                            showGridlines columnResizeMode="expand" value={routePlan} tableStyle={{ minWidth: '90rem' }}
                        >
                            <Column field="planId" header="Plan" body={planIdTemplate} />
                            <Column field="date" header="Tarih" body={dateFormatterBody} />
                            <Column field="plate" header="Plaka" body={planIdTemplate3} />
                            <Column field="isDone" header="Tamamlanma" body={planIdTemplate4} />
                            <Column field="depot" header="Depo" />
                            <Column field="address" header="Durak" />
                            <Column field="isArrive" header="Varış" />
                            <Column field="arrivedAt" header="Varış Tarihi" body={arrivedAtFormatterBody1} />
                            <Column field="isDeparted" header="Çıkış" />
                            <Column field="departedAt" header="Çıkış Tarihi" body={departedAtFormatterBody2} />
                            <Column field="location" header="Lokasyon" />
                            <Column field="arriveRealizationResponse" header="Ortec varış" />
                            <Column field="departRealizationResponse" header="Ortec çıkış" />
                            <Column field="resend" header="Tekrar Gönder" body={resendBodyTemplate} />
                        </DataTable>

                        <Paginator first={first} rows={rows} totalRecords={totalPageCount} onPageChange={onPageChange} />
                    </>}
                </div>
            </div>
        </div>
    )

}

export default Realization;