
import React, { useState } from 'react';
import { Form, Field } from 'react-final-form';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import { classNames } from 'primereact/utils';
import { Divider } from 'primereact/divider';
import { Message } from 'primereact/message';
import AuthService from "../services/auth.service";
import { useNavigate } from "react-router-dom";
import '../App.css';

export const LoginForm = () => {
    const [countries, setCountries] = useState([]);
    const [showMessage, setShowMessage] = useState(false);
    const [formData, setFormData] = useState({});
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const navigate = useNavigate();

    const onChangeUsername = (value) => {
        setUsername(value);
    };

    const onChangePassword = (value) => {
        setPassword(value);
    };
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const validate = (data) => {
        let errors = {};

        if (!data.name) {
            errors.name = 'Name is required.';
        }

        if (!data.password) {
            errors.password = 'Password is required.';
        }


        return errors;
    };

    const onSubmit = (data, form) => {
        console.log(data);
        setFormData(data);
        // setUsername(data.name)
        //   setPassword(data.password)
        AuthService.login(username, password).then(
            () => {
                navigate("/realization");
                window.location.reload();
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setLoading(false);
                setMessage(resMessage);
            }
        );
        setShowMessage(true);

    };

    const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
    const getFormErrorMessage = (meta) => {
        return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
    };


    return (

        <div >
            <div className="flex justify-content-evenly flex-wrap" >
                <img src="/image.png" style={{ width: '400px', marginTop: '15vh' }} />


            </div>
            <div className="flex justify-content-evenly flex-wrap" >
                <img src="/realizasyon.png" style={{ width: '200px' }} />
            </div>

            <div className="flex justify-content-evenly flex-wrap mb-5" >
             {message && <Message severity="error" text="Kullanıcı Adı veya Şifre Hatalı"></Message> }
            </div>



            <div className="flex justify-content-evenly flex-wrap" >
                <Form onSubmit={onSubmit} initialValues={{ name: '', password: '' }} validate={validate} render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit} className="p-fluid">
                        <Field name="name" render={({ input, meta }) => (
                            <div className="field">
                                <span className="p-float-label">
                                    <InputText id="name" {...input} autoFocus onChange={(e, val) => {
                                        onChangeUsername(e.target.value)
                                        input.onChange(e.target.value);
                                    }} className={classNames({ 'p-invalid': isFormFieldValid(meta) })} />
                                    <label htmlFor="name" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Kullanıcı Adı</label>
                                </span>
                            </div>
                        )} />

                        <Field name="password" render={({ input, meta }) => (

                            <div className="field">
                                <span className="p-float-label">
                                    <Password id="password" {...input} feedback={false} onChange={(e, val) => {
                                        onChangePassword(e.target.value)
                                        input.onChange(e.target.value);
                                    }} toggleMask className={classNames({ 'p-invalid': isFormFieldValid(meta) })} />
                                    <label htmlFor="password" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Şifre</label>
                                </span>
                            </div>
                        )} />


                        <Button type="submit" label="Giriş" className="bg-blue-700 hover:bg-blue-400  mt-2" />
                    </form>
                )} />
            </div>
        </div>
    );
}
export default LoginForm;