import axios from "axios";
const API_URL = "https://carrefour-realizasyon.triomobil.com/";
//const API_URL = "http://localhost:7055/";
const login = (username, password) => {

  const body = {
    username: username,
    password: password
  }

  return axios
    .post(API_URL + "login", {
      username,
      password,
    })
    .then((response) => {
      if (response.data.token) {
        localStorage.setItem("token",response.data.token);
        localStorage.setItem("role",response.data.role);
      }

      return response.data;
    });
};

const logout = () => {
  localStorage.removeItem("token");
};

const getCurrentUser = () => {

  return localStorage.getItem("token");
};

const getCurrentUserRole = () => {

  return localStorage.getItem("role");
};

const AuthService = {
  login,
  logout,
  getCurrentUser,
  getCurrentUserRole
}

export default AuthService;
