import axios from "axios";

const API_URL = "https://carrefour-realizasyon.triomobil.com/";
//const API_URL = "http://localhost:7055/";

const api = axios.create({
    baseURL: API_URL,
    headers: {
      'Content-Type': 'application/json',
     // 'Authorization': 'Bearer ' + localStorage.getItem("token")
    }
  });

  api.interceptors.response.use(
    (response) => response,
    (error) => {
        if(error.response.status === 403 || error.response.status === 401){
            localStorage.clear();
            window.location.href="/"
        }
    }
  )

const postApi = (endpoint, body) => {

    return api.post(endpoint, body,{ headers: {
        'Authorization': 'Bearer ' + localStorage.getItem("token")

    }});

}

const getApi = (endpoint, params) => {

    return api.get(endpoint, { params, headers: {
        'Authorization': 'Bearer ' + localStorage.getItem("token")
    } });
}

const getDownload = (endpoint, params) => {
    
    return api.get(endpoint, { params, 
        responseType: 'blob',
        headers: {
        'Authorization': 'Bearer ' + localStorage.getItem("token")
    } });
}

const postDownload = (endpoint, body) => {
    
    return api.post(endpoint, body, {
        responseType: 'blob',
        headers: {
        'Authorization': 'Bearer ' + localStorage.getItem("token")
    } });
}

const IntegrationService = {
    postApi,
    getApi,
    getDownload,
    postDownload
}


export default IntegrationService;