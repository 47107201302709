import { Navigate } from 'react-router-dom';
import AuthService from "../services/auth.service"; // AuthService'i eklemeyi unutma

const PrivateRoute = ({ children, roles }) => {
  const userRole = AuthService.getCurrentUserRole(); // Kullanıcının rolünü al
  
  if (!AuthService.getCurrentUser()) {
    // Kullanıcı giriş yapmadıysa, giriş sayfasına yönlendir
    return <Navigate to="/" />;
  }

  if (roles && roles.indexOf(userRole) === -1) {
    // Eğer kullanıcı rolü yetkili değilse, no-access sayfasına yönlendir
    return <Navigate to="/no-access" />;
  }

  // Eğer her şey uygunsa, sayfayı render et
  return children;
};

export default PrivateRoute;
