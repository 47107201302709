import axios, { AxiosError } from "axios";

const API_URL = "https://carrefour-realizasyon.triomobil.com/";
//const API_URL = "http://localhost:7055/";

const getAlarmEMailAddress = (alarmType) => {
  return axios.get(API_URL + "alarmEmailAddress", {
    params: {
      alarmType
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`, // Bearer token burada ekleniyor
    }
  })
    .then((response) => {

      console.log(response.data)

      const res = response.data;

      return res;

    });
}


const updateAlarmEMailAddress = (id, person, mail) => {
  const requestBody = {
    id: id,
    relatedPerson: person,
    email: mail
  };

  return axios.put(API_URL + "alarmEmailAddress/" + id, requestBody, {
    params: {
      alarmType
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`, // Bearer token burada ekleniyor
    }
  })
    .then((response) => {

      console.log(response.data)

      const res = response.data;

      return res;

    });
}


const deleteAlarmEMailAddress = (id) => {

  return axios.delete(API_URL + "alarmEmailAddress/" + id, {
    data: {
      id: id
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`, // Bearer token burada ekleniyor
    }
  }
  )
    .then((response) => {

      console.log(response.data)

      const res = response.data;

      return res;

    }).catch(err => {
      throw err;
    });
}


const createAlarmEMailAddress = (dpCode, dpName, unt, person, mail, type) => {
  const requestBody = {
    depotCode: dpCode,
    depotName: dpName,
    unit: unt,
    relatedPerson: person,
    email: mail,
    alarmType: type
  };

  return axios.post(API_URL + "alarmEmailAddress", requestBody, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`, // Bearer token burada ekleniyor
    }
  })
    .then((response) => {

      console.log(response.data)

      const res = response.data;

      return res;

    });
}


export default {
  getAlarmEMailAddress,
  createAlarmEMailAddress,
  deleteAlarmEMailAddress,
  updateAlarmEMailAddress
}